import React, {useContext, useEffect, useState} from 'react';
import {Button} from "primereact/button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";

const config = {
    headers: {
        accept: "application/json",
    },
};

const addAvatarURL = `${process.env.REACT_APP_URL_BASE_PATH}add-avatar`;
const deleteAvatarURL = `${process.env.REACT_APP_URL_BASE_PATH}delete-avatar`;

//uploadlimiet in bytes
let uploadLimit = 1000000

function AvatarUpload(props) {
    const initalAvatar = useContext(UserContext).user_avatar
    const navigate = useNavigate()
    const [avatarFile, setAvatarFile] = useState(null)
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [fileTooLarge, setFileTooLarge] = useState(false)
    const [currentAvatar, setCurrentAvatar] = useState(initalAvatar)

    useEffect(() => {
        setCurrentAvatar(initalAvatar)
        if (avatarFile) {
            if (avatarFile.size < uploadLimit) {
                setFileTooLarge(false)
                setSaveDisabled(false)
                setCurrentAvatar(window.URL.createObjectURL(document.getElementById('uploadAvatar').files[0]))
            } else {
                setFileTooLarge(true)
                setSaveDisabled(true)
            }
        } else {
            setSaveDisabled(true)
        }
    }, [avatarFile, initalAvatar]);

    const uploadAvatar = () => {
        const data = new FormData()
        data.append('avatar', avatarFile)
        axios
            .post(addAvatarURL, data, config)
            .then((response) => {
               if (response.status === 200) {
                   navigate(0)
               }
            })
            .catch((error) => {
               console.log(error)
            });
    }

    const deleteAvatar = () => {
        axios.delete(deleteAvatarURL).then((response) => {
            if (response.status === 200) {
                navigate(0)
            }
        })
            .catch((error) => {
                console.log(error)
            });
    }
    
    return (
        <div className="flex flex-column w-fit gap-4 border border-solid border-1 border-200 p-3 border-round">
            {currentAvatar && currentAvatar.length > 1 &&
                <div><img alt='profile avatar' id='uploadedAvatar' src={currentAvatar} width="400px" className="border-round" /></div>}
            <div className="flex gap-4">
                {currentAvatar && currentAvatar.length > 1 &&  <Button label='Verwijderen' className="p-button-danger p-button-text p-button-sm" icon='pi pi-trash' onClick={() => {deleteAvatar()}}/>}
                <label className="p-button p-button-success p-button-sm" htmlFor="uploadAvatar"><i className="pi pi-upload mr-3 text-xs"></i>Upload een foto</label>
                <input name="avatar" hidden id="uploadAvatar" type={"file"} accept={"image/*"} onChange={(inputEvent) => {setAvatarFile(inputEvent.target.files[0])}}/>
                <Button disabled={saveDisabled} label="Opslaan" onClick={() => {uploadAvatar()}} className="p-button-success p-button-outlined p-button-sm" icon="pi pi-save"/>
            </div>
            <div hidden={!fileTooLarge} style={{display: !fileTooLarge ? 'none' : 'flex'}} className="align-items-center justify-content-center text-center p-2 border-round bg-red-100 text-red-600 text-sm border border-1 border-red-300"><i className="pi pi-exclamation-circle mr-2"/>Maximum limiet is 1MB</div>
        </div>
    );
}

export default AvatarUpload;