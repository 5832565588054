import { Chart } from "primereact/chart";
import React, { useEffect, useState, useContext } from "react";
import gradient from "chartjs-plugin-gradient";
import axios from "axios";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import NoDataMessage from "../../../UI/NoDataMessage";
import { setDate } from "../../../../utils/dateTime";

let delayed;

const delayTimeInMs = 30;
const dataSetLength = 100;
const chartStyling = {
  layout: { padding: { left: 10, right: 30 } },
  plugins: {
    legend: {
      display: false,
    },
  },
  animation: {
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default" && !delayed) {
        delay = context.dataIndex * delayTimeInMs + context.datasetIndex * dataSetLength;
      }
      return delay;
    },
  },
  tooltip: {
    display: false,
  },
  datalabels: {
    display: false,
  },
  scales: {
    x: {
      grid: {
        display: true,
        borderDash: [2, 5],
      },
      ticks: {
        maxRotation: 0,
        display: true,
        maxTicksLimit: 5,
      },
    },
    y: {
      suggestedMin: 0,
      grid: {
        display: false,
      },
    },
  },
};

const config = {
  headers: {
    accept: "application/json",
  },
};

export default function StatsChartTimeline(props) {
  const [checkForData, setCheckForData] = useState(false);

  const { WebshopOrderId } = useContext(WebshopOrderIdContext);

  

  const [data, setData] = useState(null);

  useEffect(() => {
    if (WebshopOrderId) {
      const url = `${process.env.REACT_APP_URL_BASE_PATH
        }stats-order-dates?webshoporder_id=${WebshopOrderId}&startdate=${setDate(
          props.timespan
        )}&enddate=${setDate()}`;

      const getOrderData = async () => {
        axios.get(url, config).then((response) => {
          const dataResponse = response.data;
          const responseDates = [];
          dataResponse.forEach((element) => {
            responseDates.push(element["order_date"]);
          });

          const count = {};
          for (const responseDate of responseDates) {
            count[responseDate]
              ? (count[responseDate] += 1)
              : (count[responseDate] = 1);
          }

          const graphData = [];
          const graphLabels = [];
          for (const date in count) {
            var datePart = date.match(/\d+/g),
              month = datePart[1],
              day = datePart[2];

            const formattedDate = day + "/" + month;
            graphLabels.push(formattedDate);
            graphData.push(count[date]);
          }

          setData({
            labels: graphLabels,
            datasets: [
              {
                data: graphData,
                fill: true,
                label: "Bestellingen",
                gradient: {
                  backgroundColor: {
                    axis: "y",
                    colors: {
                      0: "rgba(46, 212, 122, 0)",
                      10000: "rgba(46, 212, 122, 0.2)",
                    },
                  },
                },
                borderColor: "#2ed47a",
                borderWidth: 1.5,
                pointRadius: 1,
                pointHitRadius: 55,
                pointStyle: "circle",
                tension: 0.4,
              },
            ],
          });
          if (graphData.length === 0) {
            setCheckForData(false);
          } else {
            setCheckForData(true);
          }
        });
      };
      getOrderData();
    }
    return () => {
      setData(null);
    }
  }, [WebshopOrderId, props.timespan]);

  return (
    <div style={{ cursor: "crosshair", paddingTop: "5rem" }}>
      {!data && <LoadingAnimation height='400px' />}
      {!checkForData && data && <NoDataMessage />}
      <Chart
        hidden={!checkForData}
        type='line'
        data={data}
        options={chartStyling}
        plugins={[gradient]}
      />
    </div>
  );
}
