import React, {useContext, useEffect} from "react";
import { Button } from "primereact/button";
import {UserContext} from "../../contexts/UserContext";

export default function DarkModeButton() {
  const darkModeKey = "isDarkMode";
  const theme = useContext(UserContext)

  useEffect(() => {
    const changeTheme = (darkMode) => {
      let themeLink = document.getElementById("app-theme");
      const theme = darkMode ? "/arya-green" : "/tailwind-light";
      localStorage.setItem(darkModeKey, darkMode);
  
      if (themeLink) {
        themeLink.href = theme + "/theme.css";
      }
    };
    changeTheme(theme.user_dark_mode);
  }, [theme.user_dark_mode]);

  return (
    <>
      {!theme.user_dark_mode && (
        <Button
          icon='pi pi-moon'
          className='p-button-text p-button-plain'
          onClick={() => {
            theme.setUserDarkMode(true);
          }}
        />
      )}
      {theme.user_dark_mode && (
        <Button
          icon='pi pi-sun'
          className='p-button-text'
          onClick={() => {
            theme.setUserDarkMode(false);
          }}
        />
      )}
    </>
  );
}
