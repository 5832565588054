import React, {useContext, useEffect, useState} from "react";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import axios from "axios";

const config = {
    headers: {
        accept: "application/json",
    },
};

let previewSize = 4;

export default function StatsCardTop20() {

    const { WebshopOrderId } = useContext(WebshopOrderIdContext);
    const [previewData, setPreviewData] = useState(null);

    useEffect(() => {
        if (WebshopOrderId) {
            setPreviewData(null);
            const url = `${process.env.REACT_APP_URL_BASE_PATH}stats-gifts?webshoporder_id=${WebshopOrderId}`;
            const getGiftData = () => {
                axios.get(url, config).then((response) => {
                    const data = response.data
                    setPreviewData(data.slice(0, previewSize));
                });
            };
            getGiftData();
        }
    }, [WebshopOrderId]);

  return (
    <div className='px-4 pt-4 flex flex-column gap-2'>
        <div className='pb-2'>
      Benieuwd welke producten het beste presteren in de webshop? Bekijk dan de
      top 20 gekozen geschenken.
        </div>
        {previewData && previewData.map(({product, aantal_gekozen}, i) => {
            return (
                <div key={product} className='flex justify-content-between align-items-center border-green-300 border-round p-2 surface-100 text-sm'>
                    <div className='flex justify-content-start align-items-center gap-2'>
                        <div className='font-bold px-2'>{i + 1}</div>
                        {product}
                    </div>
                    <div key={product + 'aantal'} className='h-full p-badge p-badge-success border-round mx-2'>
                        {aantal_gekozen} x gekozen
                    </div>

                </div>
            );
        })}
    </div>
  );
}
