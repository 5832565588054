import axios from "axios";
import React, { useEffect, useState } from "react";

axios.defaults.baseURL = process.env.REACT_APP_API;

const api_token = sessionStorage.getItem("api_token");
const fetchAvatarURL = `${process.env.REACT_APP_URL_BASE_PATH}get-avatar`;

const darkModeKey = "isDarkMode";
var initialDarkmode = localStorage.getItem(darkModeKey) === "true";

if (api_token) {
  // Set oAuth header when we have one
  axios.defaults.headers.common["Authorization"] = "Bearer " + api_token;
}

const UserContext = React.createContext({});

const UserContextProvider = ({ children }) => {
  const [user_name, setUserName] = useState("");
  const [user_roles, setUserRoles] = useState([]);
  const [user_email, setUserEmail] = useState("");
  const [user_dark_mode, setUserDarkMode] = useState(initialDarkmode)
  const [user_avatar, setUserAvatar] = useState(null)

  const hasRole = (roleName) => {
    return user_roles.includes(roleName);
  };
  const isLoggedIn = () => {
    return !!sessionStorage.getItem("api_token");
  };

  const logout = () => {
    sessionStorage.clear();
    axios.defaults.headers.common["Authorization"] = null;
    setUserName("");
    setUserRoles([]);
    setUserEmail("");
  };

  useEffect(() => {
      const fetchAvatar = () => {
          axios.get(fetchAvatarURL).then((response) => {
                  if (response.data['avatarUrl'].length > 1) {
                      setUserAvatar(response.data['avatarUrl'])
                  }
              }
          ).catch(setUserAvatar(''))
      }
    if (sessionStorage.getItem("api_token")) {
        fetchAvatar()
      setUserName(sessionStorage.getItem("user_name"));
      setUserRoles(sessionStorage.getItem("user_roles"));
      setUserEmail(sessionStorage.getItem("user_email"));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
          user_avatar,
          setUserAvatar,
        user_name,
        user_email,
        user_roles,
        setUserName,
        setUserRoles,
          user_dark_mode,
          setUserDarkMode,
        hasRole,
        logout,
        isLoggedIn,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
