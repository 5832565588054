import * as XLSX from 'xlsx';

function exportFileAsCSV(arr, name) {
  const array = [Object.keys(arr[0])].concat(arr);
  const exportArray = array
    .map((iterable) => {
      return Object.values(iterable).toString();
    })
    .join("\n");

  let csvContent = "data:text/csv;charset=utf-8," + exportArray;
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link);

  link.click();
  return
}

function exportFileAsXLSX(arr, name) {
  var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(arr)

  XLSX.utils.book_append_sheet(wb, ws, name)

  XLSX.writeFile(wb, `${name}.xlsx`)
}

export { exportFileAsCSV, exportFileAsXLSX };
