import React, {useContext, useEffect, useState} from 'react';
import {Avatar} from "primereact/avatar";
import {Card} from "primereact/card";
import {UserContext} from "../../contexts/UserContext";
import {Dialog} from "primereact/dialog";
import AvatarUpload from "./AvatarUpload";
import {useLocation} from "react-router-dom";

function ProfileCard(props) {
    const context = useContext(UserContext);
    const location = useLocation();
    const [displayAvatar, setDisplayAvatar] = useState(context.user_avatar)
    const [changeAvatar, setChangeAvatar] = useState(false)
    useEffect(() => {
        setDisplayAvatar(context.user_avatar)
    }, [context.user_avatar]);
    
    return (
        <Card
            className='p-ripple shadow-none hover:border-solid mb-5'
            style={{ borderRadius: "5px" }}>
            <div className='flex flex-column gap-3 py-2 px-2'>
                <div className='flex flex-row align-items-center gap-4'>
                    <Avatar
                        className='p-0'
                        image={displayAvatar}
                        label={context.user_name[0]}
                        size='large'
                        shape='circle'
                        onClick={() => {if (!location.pathname.includes('settings')) { setChangeAvatar(true)}}}
                    />
                    <Dialog visible={changeAvatar} onHide={() => {setChangeAvatar(false)}}><AvatarUpload/></Dialog>
                    <div id='avatar-info'>
                        <div className='font-bold' id='avatar-info-username'>
                            {context.user_name}
                        </div>
                        <div className='text-sm opacity-40' id='avatar-info-email'>
                            {context.user_email}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default ProfileCard;