import React from "react";
import { useEffect, useState, useContext } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import NoDataMessage from "../../../UI/NoDataMessage";
import {exportFileAsXLSX} from "../../../../utils/exportFiles";
import { setDate } from "../../../../utils/dateTime";

const config = {
  headers: {
    accept: "application/json",
  },
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.padding = "10px";

      li.onclick = () => {
        const { type } = chart.config;
        if (type === "pie" || type === "doughnut") {
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "20px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.width = "20px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
      ul.style.display = "flex";
      ul.style.flexDirection = "column";
      ul.style.justifyContent = "space-evenly";
    });
  },
};

const chartStyling = {
  responsive: true,
  layout: {
    padding: 20,
  },
  plugins: {
    legend: {
      display: false,
    },
    htmlLegend: {
      containerID: "doughnut-legend",
    },
  },

  cutout: "85%",
  borderJoinStyle: "round",
  borderWidth: 0,
  spacing: 0,
  clip: 20,
  animation: {
    animateScale: false,
    animateRotate: true,
    duration: 1000,
    easing: "easeInOutSine",
  },
};

export default function StatsChartOrders(props) {
  const [activeOrdersState, setActiveOrdersState] = useState([]);
  const [completedOrdersState, setCompletedOrders] = useState([]);
  const [checkForData, setCheckForData] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [data, setData] = useState(null);
  const { WebshopOrderId } = useContext(WebshopOrderIdContext);

  useEffect(() => {
    if (WebshopOrderId) {
      const url = `${process.env.REACT_APP_URL_BASE_PATH
        }stats-order-status?webshoporder_id=${WebshopOrderId}&startdate=${setDate(
          props.timespan
        )}&enddate=${setDate()}`;
  
   
  
      const getOrderData = () => {
        axios.get(url, config).then((response) => {
          const dataResponse = response.data;
          const activeOrders = [];
          const completedOrders = [];
          setOrderCount(dataResponse.length);
          dataResponse.forEach((element) => {
            if (element.status === "open") {
              activeOrders.push(element);
            }
            if (element.status === "besteld") {
              completedOrders.push(element);
            }
          });
  
          setActiveOrdersState(activeOrders);
          setCompletedOrders(completedOrders);
  
          if (dataResponse.length > 0) {
            setCheckForData(true);
          } else {
            setCheckForData(false);
          }
  
          setData({
            labels: ["Voltooide bestellingen", "Moet nog bestellen"],
            datasets: [
              {
                data: [completedOrders.length, activeOrders.length],
                backgroundColor: ["#2ed47a", "#FF9900"],
                hoverBackgroundColor: ["#2ed47a", "#FF9900"],
                hoverOffset: 20,
              },
            ],
          });
        });
      };
    
      getOrderData();
    }
    return () => {
      setData(null);
    };
  }, [WebshopOrderId, props.timespan]);

  const exportDropdownSelectItems = [
    { label: "Moet nog bestellen", value: [activeOrdersState, 'Moet nog bestellen'] },
    { label: "Voltooide bestellingen", value: [completedOrdersState, 'Voltooide bestellingen'] },
  ];

  return (
    <>
      <div
        id='stats-chart-orders-header'
        className='px-4 flex flex-row justify-content-between align-items-center surface-ground '>
        <span>Totaal orders: {orderCount}</span>
        <span>
          <Dropdown
            value={"Exporteer"}
            options={exportDropdownSelectItems}
            onChange={(e) => exportFileAsXLSX(e.value[0], `${e.value[1]}-${props.timespan}`)}
            placeholder='Exporteer'
          />
        </span>
      </div>
      <div className='statistics-container-piechart p-3'>
        <div className='statistics-content-chart flex justify-content-center align-items-center'>
          {!data && <LoadingAnimation />}
          {!checkForData && data && <NoDataMessage />}
          <Chart
            hidden={!checkForData}
            style={{ padding: "3rem" }}
            width='100%'
            type='doughnut'
            data={data}
            options={chartStyling}
            plugins={[htmlLegendPlugin]}
          />
        </div>
        <div
          id='doughnut-legend'
          className='pt-5 flex justify-content-evenly'></div>
      </div>
    </>
  );
}
