import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import axios from "axios";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import { Badge } from "primereact/badge";
import { exportFileAsXLSX } from "../../../../utils/exportFiles";
import { setDate } from "../../../../utils/dateTime";

const source = axios.CancelToken.source();
const config = {
  headers: {
    accept: "application/json",
  },
  cancelToken: source.token,
};

export default function Orders() {
  const { WebshopOrderId } = useContext(WebshopOrderIdContext);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rows] = useState(10);
  const [data, setData] = useState(null);
  const [orderCount, setOrderCount] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (WebshopOrderId) {
      const url = `${process.env.REACT_APP_URL_BASE_PATH
        }stats-orders?webshoporder_id=${WebshopOrderId}&startdate=${setDate(
          "alles"
        )}&enddate=${setDate()}&rows=${rows}&page=${page}`;
  
      const getOrderData = () => {
        axios
          .get(url, config)
          .then((response) => {
            const dataResponse = response.data.orders;
            setOrderCount(response.data.total_count);
            setData(dataResponse);
            setLoading(false);
          })
          .catch((error) => {
            return;
          });
      };

      setLoading(true);
      getOrderData();
    }
  }, [WebshopOrderId, page, rows]);

  useEffect(() => {
    return () => {
      setPage(1);
    }
  }, [WebshopOrderId]);

  const statsCardOrdersHeader = (title) => {
    return (
      <div className='flex flex-row justify-content-between py-1 px-4'>
        <h3>{title}</h3>{" "}
        <Button
          icon='pi pi-chevron-left'
          label='Terug naar overzicht'
          className='p-button-text p-button-success'
          onClick={() => navigate("/stats")}
        />
      </div>
    );
  };

  const maxPage = Math.ceil(orderCount / rows);
  const tableFooter = () => {
    return (
      <div className='flex flex-row justify-content-between align-items-center p-3'>
        {`Pagina ${page} van ${maxPage}`}
        <div className='flex flex-row justify-content-center'>
          <Button
            icon='pi pi-angle-double-left'
            className='m-1 p-button-text p-button-plain'
            onClick={() => {
              setPage(1);
            }}
          />

          <InputNumber
            format={false}
            allowEmpty={false}
            value={page}
            onChange={(e) => {
              setPage(e.value);
            }}
            min={1}
            max={maxPage}
            showButtons
            buttonLayout='horizontal'
            inputStyle={{ width: "100px", textAlign: "center" }}
            incrementButtonClassName='p-button-text p-button-plain'
            decrementButtonClassName='p-button-text p-button-plain'
            incrementButtonIcon='pi pi-angle-right'
            decrementButtonIcon='pi pi-angle-left'
          />
          <Button
            icon='pi pi-angle-double-right'
            className='m-1 p-button-text p-button-plain'
            onClick={() => {
              setPage(maxPage);
            }}
          />
        </div>
        <div>{`Totaal orders: ${orderCount}`}</div>
      </div>
    );
  };

  const dateStyle = (rowData) => {
    var datePart = rowData.order_date.match(/\d+/g),
      year = datePart[0].substring(0, 4),
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  };

  const orderlineCountStyle = (rowData) => {
    return (
      <Badge
        value={String(rowData.orderlines.length)}
        severity={rowData.orderlines.length > 0 ? "success" : "warning"}
      />
    );
  };

  const orderlinesStyle = (rowData) => {
      return( <div>{rowData.orderlines.map((element) => {
        return (<span style={{display: 'block', paddingBlock: '5px'}}>{element}</span>)
      })}</div>);
  };

  const exportURL = `${
    process.env.REACT_APP_URL_BASE_PATH
  }stats-orders?webshoporder_id=${WebshopOrderId}&startdate=${setDate(
    "alles"
  )}&enddate=${setDate()}`;

  const exportAll = () => {
    const arr = []
    setButtonLoading(true);
    axios.get(exportURL, config).then((response) => {
      const dataResponse = response.data.orders;
      setButtonLoading(false);
      dataResponse.forEach((element) => {
            element.orderlines = element?.orderlines?.join(' , ')
        arr.push(element)
          }
      )
       exportFileAsXLSX(arr, "alle orders");
    });
  };

  const ordersHeader = () => {
    return (
      <Button
        label={
          buttonLoading
            ? "Exporteren...  Bij een groot aantal orders kan dit even duren."
            : "Exporteer alle orders"
        }
        icon='pi pi-file'
        loading={buttonLoading}
        className={"p-button-plain p-button-text"}
        onClick={() => {
          exportAll();
        }}
      />
    );
  };

  return (
    <div>
      <Card
        header={statsCardOrdersHeader("Alle bestellingen")}
        footer={tableFooter()}>
        {!data && <LoadingAnimation />}
        <DataTable
          emptyMessage='Geen bestellingen gevonden'
          responsiveLayout='scroll'
          scrollHeight='60vh'
          scrollable={true}
          loading={loading}
          hidden={!(data && data.length > 0) }
          value={data}
          header={ordersHeader}>
          <Column field='id' header='id' style={{ maxWidth: "5em" }}></Column>
          <Column field='name' header='naam' style={{ width: "30%" }}></Column>
          <Column
            field='orderlines'
            header='product'
            body={orderlinesStyle}></Column>
          <Column
            field={"orderlines"}
            header='aantal'
            body={orderlineCountStyle}
            dataType='number'
            sortField='orderlines.length'
            style={{ maxWidth: "8rem" }}></Column>
          <Column
            field='order_date'
            header='datum'
            sortField='order_date'
            body={dateStyle}
            style={{ maxWidth: "12rem" }}></Column>
        </DataTable>
      </Card>
    </div>
  );
}
