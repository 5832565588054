import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import { Badge } from "primereact/badge";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import NoDataMessage from "../../../UI/NoDataMessage";
import { setDate } from "../../../../utils/dateTime";
import {exportFileAsXLSX} from "../../../../utils/exportFiles";

const config = {
  headers: {
    accept: "application/json",
  },
};

export default function StatsCardOrders(props) {

  const { WebshopOrderId } = useContext(WebshopOrderIdContext);
  const [orderCount, setOrderCount] = useState(null);
  const [data, setData] = useState(null);
  const dt = useRef(null);

  useEffect(() => {
    if (WebshopOrderId) {
      setData(null);
      const url = `${process.env.REACT_APP_URL_BASE_PATH
        }stats-orders?webshoporder_id=${WebshopOrderId}&startdate=${setDate(
          props.timespan
        )}&enddate=${setDate()}&rows=${props.limit}`;
  
  
      const getOrderData = async () => {
        axios.get(url, config).then((response) => {
          const dataResponse = response.data.orders;
          setOrderCount(response.data.total_count);
          setData(dataResponse);
        });
      };
      getOrderData();
    }
  }, [WebshopOrderId, props.timespan, props.limit]);

  const orderlinesStyle = (rowData) => {
    return( <div>{rowData.orderlines.map((element) => {
      return (<span key={element} style={{display: 'block', paddingBlock: '5px'}}>{element}</span>)
    })}</div>);
  };

  const orderlineCountStyle = (rowData) => {
    return (
      <Badge
        value={String(rowData.orderlines.length)}
        severity={rowData.orderlines.length > 0 ? "success" : "warning"}
      />
    );
  };

  const dateStyle = (rowData) => {
    var datePart = rowData.order_date.match(/\d+/g),
      year = datePart[0].substring(0, 4), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  };

  const exportAll = (data) => {
    const arr = []
    data.map((element) => {
          let newElement = { ...element}
          newElement.orderlines = element?.orderlines?.join('\n')
          arr.push(newElement)
        }
    );
    exportFileAsXLSX(arr, `Meest recente orders-${props.timespan}`);
  };

  const header = (
    <div className='flex align-items-center export-buttons justify-content-between'>
      <div>
        {props.limit && `De ${props.limit} meest recente bestellingen`}
        {props.fullWidth && `Totaal: ${orderCount}`}
      </div>

      <Button
        type='button'
        label='Exporteer als XLSX'
        icon='pi pi-file'
        onClick={() => {exportAll(data)}}
        className='mr-2 p-button-text p-button-plain'
      />
    </div>
  );
  return (
    <div className='stats-orders-container'>
      <div className='order-table'>
        {!data && <LoadingAnimation />}
        {data && data.length === 0 && (
          <NoDataMessage timespan={props.timespan} />
        )}
        <DataTable
          emptyMessage='Geen bestellingen gevonden'
          hidden={!(data && data.length > 0)}
          ref={dt}
          value={data}
          header={header}
          scrollable
          scrollHeight={props.fullWidth ? "90vh" : "430px"}>
          <Column
            field='id'
            header='id'
            sortable={props.fullWidth}
            style={{ maxWidth: "5em" }}></Column>
          <Column
            field='name'
            header='naam'
            sortable={props.fullWidth}
            style={{ maxWidth: "10rem" }}></Column>
          <Column
            field='orderlines'
            header='product'
            sortable={props.fullWidth}
            body={orderlinesStyle}></Column>
          <Column
            field={"orderlines"}
            header='aantal'
            body={orderlineCountStyle}
            dataType='number'
            sortable={props.fullWidth}
            sortField='orderlines.length'
            style={{ maxWidth: "8rem" }}></Column>
          <Column
            field='order_date'
            header='datum'
            sortField='order_date'
            sortable={props.fullWidth}
            body={dateStyle}
            style={{ maxWidth: "12rem" }}></Column>
        </DataTable>
      </div>
    </div>
  );
}
