import React from "react";
import { useEffect, useState, useContext } from "react";
import { Chart } from "primereact/chart";
import axios from "axios";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import NoDataMessage from "../../../UI/NoDataMessage";

const config = {
    headers: {
        accept: "application/json",
    },
};

let delayed;

const delayTimeInMs = 30;
const dataSetLength = 100;
const chartStyling = {
    layout: { padding: { left: 10, right: 30 } },
    animation: {
        delay: (context) => {
            let delay = 0;
            if (context.type === "data" && context.mode === "default" && !delayed) {
                delay = context.dataIndex * delayTimeInMs + context.datasetIndex * dataSetLength;
            }
            return delay;
        },
    },
    tooltip: {
        display: false,
    },
    datalabels: {
        display: false,
    },
    scales: {
        x: {
            grid: {
                display: true,
                borderDash: [2, 5],
            },
            ticks: {
                display: true,
            },
        },
        y: {
            suggestedMin: 0,
            grid: {
                display: false,
            },
            ticks: {
                callback: function(value, index, values) {
                    return '€' + value;
                }
            }
        },
    },
};

export default function StatsChartDonations(props) {
    const { WebshopOrderId } = useContext(WebshopOrderIdContext);
    const [checkForData, setCheckForData] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (WebshopOrderId) {
            setData(null);
            const url = `${process.env.REACT_APP_URL_BASE_PATH}stats-donations?webshoporder_id=${WebshopOrderId}`;
            const getDonationData = () => {
                axios.get(url, config).then((response) => {

                    const data = response.data
                    const graphLabels = []
                    const graphData = []

                    data.map(({bedrag, product}) =>{
                        bedrag && graphData.push(parseFloat(bedrag))
                        product && graphLabels.push(product)
                        return
                    })

                    setData({labels: graphLabels,
                    datasets: [{
                        label: 'Totaal bedrag donaties',
                        backgroundColor: "#2ed47a",
                        data: graphData,
                    }]});

                    if (graphData.length === 0) {
                        setCheckForData(false);
                    } else {
                        setCheckForData(true);
                    }

                });
            };
            getDonationData();
        }
        return setData(null)
    }, [WebshopOrderId]);

    return (
        <>
            <div className='statistics-container-barchart'>
                <div className='statistics-content-chart flex justify-content-center align-items-center'>
                    {!data && <LoadingAnimation height='400px' />}
                    {!checkForData && data && <NoDataMessage />}
                    <Chart
                        hidden={!checkForData}
                        style={{ padding: '3rem' }}
                        width='100%'
                        height='100%'
                        type='bar'
                        data={data}
                        options={chartStyling}
                    />
                </div>
            </div>
        </>
    );
}
