const setDate = (timespan) => {
    let newDate = new Date();
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let maxDays = 0;
    let year = newDate.getFullYear();

    if (timespan === "week") {
        if (day > 7){
            day -= 7;
        }
        else {
           maxDays = Math.abs(day - 7)
            month -= 1;
           day = 30 - maxDays;
        }
    }

    if (timespan === "month") {
        if (month === 1) {
            year -= 1
            month = 12
        }
        else {
            month -= 1;
        }
    }

    if (month < 10) {
      month = "0" + month;
    }

    if (timespan === "alles") {
      year -= 10;
    }

    return `${year}-${month}-${day} 00:00:00`;
};
  
export { setDate };