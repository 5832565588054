import React, { useContext} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "primereact/button";

export default function LogoutButton() {
  const navigate = useNavigate();
  const context = useContext(UserContext);

  const logout = () => {
    axios.post(process.env.REACT_APP_MTG_AUTH + "/logout").then((res) => {
      if (res.data.msg === "ok") {
        // userId and api_token
        sessionStorage.clear();
        context.setUserName("");
        context.setUserRoles([]);
        axios.defaults.headers.common["Authorization"] = null;
        // Jump
        navigate("/login");
      }
    });
  };
  return (
    <Button
      className='p-button-danger p-button-text p-button-sm flex flex-row align-items-center'
      onClick={() => {
        logout();
      }}>
      <i className='pi pi-sign-out pr-2 text-sm ' /> Log uit
    </Button>
  );
}
