import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContextProvider } from "./contexts/UserContext";
import PageLogin from "./pages/PageLogin";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import PageHome from "./pages/PageHome";
import Stats from "./components/dashboard/Stats";
import Settings from "./components/dashboard/Settings";
import DashboardMain from "./components/dashboard/DashboardMain";
import Planning from "./components/dashboard/Planning";
import StatsPageTop20 from "./components/dashboard/stats-components/pages/Gifts";
import Gifts from "./components/dashboard/stats-components/pages/Gifts";
import Donations from "./components/dashboard/stats-components/pages/Donations";
import Orders from "./components/dashboard/stats-components/pages/Orders";

const api_token = sessionStorage.getItem("api_token");

if (!api_token && document.location.pathname !== "/login") {
  document.location.href = "/login";
}

function App() {
  if (!api_token && document.location.pathname !== "/login") return null;
  return (
    <div className='App'>
      <BrowserRouter>
        <UserContextProvider>
          <Routes>
            <Route exact path='/login' element={<PageLogin />} />
            <Route exact path='/' element={<PageHome />}>
              <Route exact path='stats' element={<Stats />} />
              <Route exact path='top20' element={<StatsPageTop20 />} />
              <Route exact path='donaties' element={<Donations />} />
              <Route exact path='geschenken' element={<Gifts />} />
              <Route exact path='orders' element={<Orders />} />
              <Route exact path='settings' element={<Settings />} />
              <Route exact path='dashboard' element={<DashboardMain />} />
              <Route exact path='planning' element={<Planning />} />
            </Route>
            <Route exact path='/login' element={<PageLogin />} />
          </Routes>
        </UserContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
