import React from "react";
import { ProgressBar } from "primereact/progressbar";

export default function LoadingAnimation() {
  return (
    <div
      className='flex flex-column m-auto p-5 align-items-center'
      style={{ width: "40%" }}>
      <h4 style={{ textAlign: "center" }}>Data aan het laden...</h4>
      <ProgressBar
        color='#2ed47a'
        mode='indeterminate'
        style={{ height: "6px", width: "100%" }}
      />
    </div>
  );
}
