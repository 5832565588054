import React, { useRef } from "react";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { useState } from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";
import { Messages } from "primereact/messages";
import AvatarUpload from "../UI/AvatarUpload";

export default function Settings() {
  const [current_password, setCurrentPassword] = useState("");
  const [password, setNewPass] = useState("");
  const [password_confirmation, setConfirmPass] = useState("");
  const messages = useRef(null);
  const config = {
    headers: {
      accept: "application/json",
    },
  };

  const putRequestData = {
    current_password,
    password,
    password_confirmation,
  };

  const resetURL = `${process.env.REACT_APP_URL_BASE_PATH}password`;

  const submitForm = () => {
    axios
      .put(resetURL, putRequestData, config)
      .then((response) => {
        if (response.status === 204) {
          messages.current.show({
            severity: "success",
            detail: "Wachtwoord is geupdated.",
          });
        }
        setCurrentPassword("");
        setNewPass("");
        setConfirmPass("");
      })
      .catch((error) => {
        const errorMessageObject = error.response.data.errors;
        for (let key in errorMessageObject) {
          messages.current.show({
            severity: "error",
            detail: errorMessageObject[key],
          });
        }
      });
  };

  const settingsHeader = () => {
    return (
      <div className='m-4'>
        <h2>Instellingen</h2>
      </div>
    );
  };

  return (
    <Card
      className='w-full shadow-1 transition-duration-500 p-3'
      header={settingsHeader}>
      <Divider />

      <div className='flex flex-column gap-5 px-4 pb-4'>
        <h4>Wachtwoord wijzigen</h4>
        <span className='p-float-label'>
          <Password
            value={current_password}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
            }}
            toggleMask
            feedback={false}
          />

          <label htmlFor='password'>Huidig wachtwoord</label>
        </span>

        <span className='p-float-label'>
          <Password
            promptLabel='Nieuw wachtwoord'
            footer={
              <>
                <Divider />
                Wachtwoord moet minimaal 8 karakters bevatten.
              </>
            }
            weakLabel='Maak een beter wachtwoord'
            mediumLabel='Uw wachtwoord is goed'
            strongLabel='Uw wachtwoord is sterk!'
            value={password}
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
            toggleMask
          />
          <label htmlFor='password'>Nieuw achtwoord</label>
        </span>
        <span className='p-float-label'>
          <Password
            value={password_confirmation}
            onChange={(e) => {
              setConfirmPass(e.target.value);
            }}
            toggleMask
            feedback={false}
          />
          <label htmlFor='password'>Herhaal wachtwoord</label>
        </span>
        <div>
        <Messages ref={messages}></Messages>
        <Button
          className='w-max p-button-success p-button-outlined p-button-sm'
          type='submit'
          label='Opslaan'
          icon='pi pi-save'
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") {
              submitForm();
            }
          }}
          onClick={submitForm}
        />
        </div>
      </div>
      <Divider/>
      <div className='flex flex-column gap-5 px-4 pb-4'>
        <h4>Avatar wijzigen</h4>
          <AvatarUpload/>
      </div>
    </Card>
  );
}
