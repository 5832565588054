import React, { useContext, useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import WebshopOrderIdContext from "../../contexts/WebshopContext";

export default function WebshopSwitcher() {
  const overlayPanelRef = useRef(null);
  var maxWebshopId = 0;
  var maxWebshop = {};

  const { WebshopOrderId, setWebshopOrderId } = useContext(
    WebshopOrderIdContext
  );
  const importedWebshopObject = JSON.parse(
    sessionStorage.getItem("client_shops")
  );

  importedWebshopObject.forEach((element) => {
    if (parseInt(element.id) > maxWebshopId) {
      maxWebshopId = parseInt(element.id);
      maxWebshop = element;
    }
  });

  const [WebshopName, setWebshopName] = useState(maxWebshop.name);
  const [WebshopUrl, setWebshopUrl] = useState(maxWebshop.webshop_url)
  if (WebshopOrderId === null) {
    setWebshopOrderId(maxWebshopId);
  }
  const webshopChangeFunction = (WebshopOrderId, WebshopName, webshop_url, e) => {
    setWebshopOrderId(WebshopOrderId);
    setWebshopName(WebshopName);
    setWebshopUrl(webshop_url)
    overlayPanelRef.current.toggle(e);
  };

  return (
    <div className='flex flex-row align-items-center'>
      <Button
        type='button'
        label='Naar webshop'
        iconPos='left'
        className='p-button-success p-button-text p-button-sm text-sm'
        icon='pi pi-desktop'
        onClick={() => {
          window.open(WebshopUrl, '_blank').focus()
        }}
        />
      <Button
        type='button'
        label={WebshopName}
        iconPos='left'
        className=' p-button-plain p-button-text text-sm'
        icon='pi pi-chevron-down'
        onClick={(e) => overlayPanelRef.current.toggle(e)}></Button>
      <OverlayPanel ref={overlayPanelRef} dismissable={true}>
        <div className='flex flex-column'>
          {importedWebshopObject.map(({ id, name, webshop_url}) => {
            return (
              <Button
                key={id}
                type='button'
                label={name}
                className='p-button-text p-button-plain text-sm text-left'
                icon='pi pi-fw pi-desktop'
                onClick={(e) => webshopChangeFunction(id, name, webshop_url, e)}
              />
            );
          })}
        </div>
      </OverlayPanel>
    </div>
  );
}
