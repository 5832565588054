import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";

const data = [
  {
    status: "open",
    titel: "taak1",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
  {
    status: "open",
    titel: "taak2",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
  {
    status: "open",
    titel: "taak3",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
  {
    status: "voltooid",
    titel: "taak4",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
  {
    status: "open",
    titel: "taak5",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
  {
    status: "open",
    titel: "taak6",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
  {
    status: "open",
    titel: "taak7",
    type: "reminder",
    gebruiker: "fakeUser1",
    datum: "14-07-2022",
  },
];

export default function Planning() {

  const [taken, setTaken] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  useEffect(() => {
    setTaken(data);
  }, []);

  const footer = `Totaal aantal taken: ${taken ? taken.length : 0}`;
  const header = `Geselecteerde taken: ${
    selectedFields ? selectedFields.length : 0
  }`;
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag severity={rowData.status === "open" ? "warning" : "success"}>
        {rowData.status}{" "}
      </Tag>
    );
  };

  const blockedTemplate = () => {
    return (
      <Card>
        {" "}
        <div className='flex flex-column align-items-center gap-5 p-4'>
          Dit gedeelte is (nog) niet in ontwikkeling.{" "}
          <Button
            label='terug'
            className='p-button-outlined'
            onClick={() => {
              window.history.back();
            }}
          />
        </div>
      </Card>
    );
  };
  return (
    <BlockUI blocked fullScreen template={blockedTemplate}>
      <Card className='w-full shadow-1 transition-duration-500'>
        <div className='planning-table'>
          <DataTable
            value={taken}
            footer={footer}
            header={selectedFields.length > 0 ? header : null}
            selectionMode='multiple'
            selection={selectedFields}
            onSelectionChange={(e) => setSelectedFields(e.value)}>
            <Column selectionMode='multiple' />
            <Column
              field='status'
              header='Status'
              body={statusBodyTemplate}></Column>
            <Column field='titel' header='Titel'></Column>
            <Column field='type' header='Type'></Column>
            <Column field='gebruiker' header='Gebruiker'></Column>
            <Column field='datum' header='Datum'></Column>
          </DataTable>
        </div>
      </Card>
    </BlockUI>
  );
}
