import React, {useContext, useEffect, useState} from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import ProfileCard from "./ProfileCard";
import {UserContext} from "../../contexts/UserContext";

export default function DashboardSidebar(props) {
  const [dashboardStyle, setDashboardStyle] = useState({ fontWeight: "bold" });
  const [settingsStyle, setSettingsStyle] = useState(null);
  const [logoTheme, setLogoTheme] = useState('')
  const context = useContext(UserContext);
  const [visible, setVisible] = props.visible;
  const isMobile = props.overlay;
  const navigate = useNavigate();
  let menuItems = [
    {
      label: "Dashboard",
      style: dashboardStyle,
      icon: "pi pi-fw pi-th-large",
      command: () => {
        setDashboardStyle({ fontWeight: "bold" });
        setSettingsStyle(null);
        navigate("stats");
      },
    },
    {
      label: "Planning",
      icon: "pi pi-fw pi-calendar",
      command: () => {
        window.open('https://app.asana.com/-/login', '_blank').focus();
      },
    },
    { label: "Offertes", icon: "pi pi-fw pi-file", disabled: true },
    { label: "Facturen", icon: "pi pi-fw pi-euro", disabled: true },
  ];

  let settings = [
    {
      label: "Instellingen",
      icon: "pi pi-fw pi-cog",
      style: settingsStyle,
      command: () => {
        setDashboardStyle(null);
        setSettingsStyle({ fontWeight: "bold" });
        navigate("settings");
      },
    },
  ];
  useEffect(() => {
    if(context.user_dark_mode){
      setLogoTheme('logos/MTG-logo-wit.svg')
    }
    else {
      setLogoTheme('logos/MTG-logo.svg')
    }
  }, [context.user_dark_mode]);


  useEffect(() => {

  }, []);

  return (
    <Sidebar
      showCloseIcon={false}
      className='p-0 p-sidebar-sm border-none shadow-6 z-0'
      visible={visible}
      modal={isMobile}
      onHide={() => setVisible(false)}>
      <div className='sidebar-container flex flex-column justify-content-between h-full w-full'>
        <div className='sidebar-container-top px-2'>
          <div
            id='logo-box'
            className='flex flex-row align-items-center gap-3 pl-2'>
            <img src={logoTheme} width='100%' alt='More than gifts logo'/>
          </div>

          <Divider className='opacity-30' />
          <ProfileCard/>
          <Menu className='border-none' model={menuItems} />
          <Divider className='opacity-30' />
          <Menu className='border-none' model={settings} />
        </div>
        <Button
          className='p-button-secondary p-button-text'
          onClick={() => {
            setVisible(false);
          }}>
          <i className='pi pi-fw pi-chevron-left pr-4' />
          Sidebar inklappen
        </Button>
      </div>
    </Sidebar>
  );
}
