import axios from "axios";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import LoadingAnimation from "../../../UI/LoadingAnimation";

const config = {
  headers: {
    accept: "application/json",
  },
};

export default function Donations() {
  const [showDetails, setShowDetails] = useState(false)
  const dt = useRef(null);
  const { WebshopOrderId } = useContext(WebshopOrderIdContext);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
 
  useEffect(() => {
    let url = ''

    if (WebshopOrderId) {
      setData(null);
      if(showDetails) {
        url = `${process.env.REACT_APP_URL_BASE_PATH}stats-donations-details?webshoporder_id=${WebshopOrderId}`;
      }
      else {
        url = `${process.env.REACT_APP_URL_BASE_PATH}stats-donations?webshoporder_id=${WebshopOrderId}`;
      }
      const getDonationData = () => {
        axios.get(url, config).then((response) => {
          setData(response.data);
        });
      };
      getDonationData();
    }
  }, [WebshopOrderId, showDetails]);

  const statsCardOrdersHeader = (title) => {
    return (
      <div className='flex flex-row justify-content-between py-1 px-4'>
        <h3>{title}</h3>{" "}
        <Button
          icon='pi pi-chevron-left'
          label='Terug naar overzicht'
          className='p-button-text p-button-success'
          onClick={() => navigate("/stats")}
        />
      </div>
    );
  };

  const bedragStyle = (rowData) => {
    return <Badge severity='success' size='' value={`€${rowData.bedrag}`} />;
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <Card header={statsCardOrdersHeader("Donaties in deze webshop")}>
      {!data && <LoadingAnimation />}
      <DataTable
        ref={dt}
        hidden={!data}
        value={data}
        scrollable
        scrollHeight='80vh'
        emptyMessage='Geen bestellingen gevonden'
        header={
        <span className='flex justify-content-between'>
          <Button
            type='button'
            label='Exporteer als CSV'
            icon='pi pi-file'
            onClick={() => exportCSV(false)}
            className='m-0 p-button-text p-button-plain'
          />
           <Button
               type='button'
               label={showDetails ? 'Minder details' : 'Meer details'}
               icon='pi pi-list'
               onClick={() => setShowDetails(!showDetails)}
               className='m-0 p-button-success p-button-outlined'
           />
        </span>
        }>
        {showDetails && <Column field='name' header='Naam' sortable />}
        <Column field='product' header='Donatie' sortable />
        <Column field='bedrag' header='Bedrag' sortable body={bedragStyle} />
      </DataTable>
    </Card>
  );
}
