import axios from "axios";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebshopOrderIdContext from "../../../../contexts/WebshopContext";
import LoadingAnimation from "../../../UI/LoadingAnimation";

const config = {
  headers: {
    accept: "application/json",
  },
};

export default function Gifts() {
  const { WebshopOrderId } = useContext(WebshopOrderIdContext);
  const dt = useRef(null);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (WebshopOrderId) {
      setData(null);
      const url = `${process.env.REACT_APP_URL_BASE_PATH}stats-gifts?webshoporder_id=${WebshopOrderId}`;
      const getGiftData = () => {
        axios.get(url, config).then((response) => {
          setData(response.data);
        });
      };
      getGiftData();
    }
  }, [WebshopOrderId]);

  const statsCardOrdersHeader = (title) => {
    return (
      <div className='flex flex-row justify-content-between py-1 px-4'>
        <h3>{title}</h3>{" "}
        <Button
          icon='pi pi-chevron-left'
          label='Terug naar overzicht'
          className='p-button-text p-button-success'
          onClick={() => navigate("/stats")}
        />
      </div>
    );
  };

  const countStyle = (rowData) => {
    return <Badge severity='success' size='' value={rowData.aantal_gekozen} />;
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <Card header={statsCardOrdersHeader("Top 20 meest gekozen geschenken")}>
      {!data && <LoadingAnimation />}
      <DataTable
        ref={dt}
        hidden={data ? false : true}
        value={data}
        header={
          <Button
            type='button'
            label='Exporteer als CSV'
            icon='pi pi-file'
            onClick={() => exportCSV(false)}
            className='m-0 p-button-text p-button-plain'
          />
        }
        emptyMessage='Geen bestellingen gevonden'>
        <Column field='product' header='Geschenk' />
        <Column
          field='aantal_gekozen'
          header='Aantal keer gekozen'
          body={countStyle}
        />
      </DataTable>
    </Card>
  );
}
