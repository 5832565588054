import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Outlet } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import PrimeReact from "primereact/api";
import WebshopOrderIdContext from "../contexts/WebshopContext";
import "primeicons/primeicons.css";
import "../styling/page-home.css";
import WebshopSwitcher from "../components/UI/WebshopSwitcher";
import DashboardSidebar from "../components/UI/DashboardSidebar";
import LogoutButton from "../components/UI/LogoutButton";
import DarkModeButton from "../components/UI/DarkModeButton";
import { Divider } from "primereact/divider";

export default function PageHome() {
  const [WebshopOrderId, setWebshopOrderId] = useState();
  var maxWebshopId = 0;
  const importedWebshopObject = JSON.parse(
    sessionStorage.getItem("client_shops")
  );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setWebshopOrderId(maxWebshopId);
  }
  , [maxWebshopId]);

  PrimeReact.ripple = true;

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  // Menu fold in/out state
  const [visible, setVisible] = useState(true);

  if (importedWebshopObject.length >= 1) {
    importedWebshopObject.forEach((element) => {
      if (parseInt(element.id) > maxWebshopId) {
        maxWebshopId = parseInt(element.id);
      }
    });
    

    const value = { WebshopOrderId, setWebshopOrderId };

    return (
      <WebshopOrderIdContext.Provider value={value}>
        <div
          className='page-home'
          style={{ backgroundColor: "var(--surface-ground)" }}>
          <DashboardSidebar
            overlay={isMobile}
            visible={[visible, setVisible]}
          />

          <Menubar
            className='border-none pr-2 shadow-1 fixed z-5 w-full p-0'
            model={null}
            start={
              !visible && (
                <Button
                  icon='pi pi-bars'
                  className='p-button-secondary p-button-text p-3'
                  onClick={(e) => setVisible(true)}
                />
              )
            }
            end={
              <div className='flex flex-row'>
                <WebshopSwitcher />
                <Divider className='m-0' layout='vertical' />
                <LogoutButton />
                <Divider className='m-0 mr-2' layout='vertical' />
                <DarkModeButton />
              </div>
            }
          />
          <div
            id='content-container'
            className='flex flex-row justify-content-center transition-duration-300'
            style={{
              marginLeft: visible && !isMobile ? "20rem" : "0rem",
              minHeight: "100vh",
            }}>
            <span className='spaced-container mt-6 w-full p-5'>
              <Outlet />
            </span>
          </div>
        </div>
      </WebshopOrderIdContext.Provider>
    );
  } else {
    return (
      <div className='page-home'>
        <div className='flex flex-column flex-wrap justify-content-start align-items-start'>
          <h1 className='text-3xl font-bold m-6'>
            Je moet minstens één webshop op je account hebben om dit portaal te
            kunnen gebruiken.
          </h1>
          <Button
            label='terug naar de inlogpagina'
            className='p-button-primary p-button-outlined p-3 m-6'
            onClick={() => (window.location.href = "/login")}
          />
        </div>
      </div>
    );
  }
}
