import React, { useState } from "react";
import { Card } from "primereact/card";
import StatsChartTimeline from "./stats-components/charts/StatsChartTimeline";
import StatsChartOrders from "./stats-components/charts/StatsChartOrders";
import StatsChartDonations from "./stats-components/charts/StatsChartDonations";
import StatsCardTop20 from "./stats-components/cards/StatsCardTop20";
import StatsCardOrders from "./stats-components/cards/StatsCardOrders";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

export default function Stats() {
  const dropdownOptions = [
    { label: "Deze week", value: "week" },
    { label: "Deze maand", value: "month" },
    { label: "Alles", value: "alles" },
  ];
  const [timelineDropdownSelected, setTimelineDropdownSelected] = useState(
    "alles"
  );
  const [piechartDropdownSelected, setPiechartDropdownSelected] = useState(
    "alles"
  );
  const [ordersDropdownSelected, setOrdersDropdownSelected] = useState("alles");

  const onTimelineDropdownChange = (event) => {
    setTimelineDropdownSelected(event.value);
  };
  const onPiechartDropdownChange = (event) => {
    setPiechartDropdownSelected(event.value);
  };
  const onOrdersDropdownChange = (event) => {
    setOrdersDropdownSelected(event.value);
  };

  const cardHeader = (title, icon, image) => {
    return (
      <div
        className='stats-card-header w-full flex flex-row justify-content-start px-4 align-items-center gap-4'
        style={{
          fontSize: "1rem",
          background: "#2ed47a",
          height: "4rem",
          color: "white",
        }}>
        <Avatar
          icon={icon}
          image={image}
          size='large'
          style={{ backgroundColor: "#ffffff20", borderRadius: "50px", padding: '10px', fill: 'white', stroke: 'white'}}
        />
        <div>
          <b>{title}</b>
        </div>
      </div>
    );
  };

  const statsCardOrdersHeader = (
    title,
    dropdownValue,
    dropdownOptions,
    dropdownChangeFunc
  ) => {
    return (
      <div className='flex flex-row justify-content-between py-1 px-4'>
        <h3>{title}</h3>{" "}
        <Dropdown
          className='p-button-success'
          value={dropdownValue}
          options={dropdownOptions}
          onChange={dropdownChangeFunc}
          optionLabel='label'
          placeholder='Deze maand'
        />
      </div>
    );
  };

  const navigate = useNavigate();

  return (
    <div
      id='stats-grid'
      className='scroll-container h-full overflow-scroll pb-3'>
      <Card
        id='stats-chart-timeline'
        className='hover:shadow-3 transition-duration-200'
        header={statsCardOrdersHeader(
          "Tijdlijn bestellingen",
          timelineDropdownSelected,
          dropdownOptions,
          onTimelineDropdownChange
        )}>
        <StatsChartTimeline timespan={timelineDropdownSelected} />
      </Card>

      <Card
        className='hover:shadow-3 transition-duration-200'
        id='stats-chart-orders'
        header={statsCardOrdersHeader(
          "Aantal bestellingen",
          piechartDropdownSelected,
          dropdownOptions,
          onPiechartDropdownChange
        )}>
        <StatsChartOrders timespan={piechartDropdownSelected} />
      </Card>

      <Card
        id='stats-card-donations'
        className='hover:shadow-3 transition-duration-200 overflow-hidden'
        header={cardHeader("Bekijk de donaties", 'pi pi-heart')}
        footer={
          <div className='px-4 pb-4'>
          <Button
            label='Bekijk alle donaties'
            icon='pi pi-chevron-right'
            className='p-button-success p-button-success w-full'
            onClick={() => {
              navigate("/donaties");
            }}
          />
          </div>
        }>
        <StatsChartDonations />
      </Card>

      <Card
        id='stats-card-gifts'
        header={cardHeader("Top 20 gekozen geschenken", null,  '/icons/trophy.svg')}
        className='hover:shadow-3 transition-duration-200 overflow-hidden'
        footer={
            <div className='px-4 pb-4'>
                <Button
                    label='Bekijk alle populaire geschenken'
                    icon='pi pi-chevron-right'
                    className='p-button-success p-button-success w-full'
                    onClick={() => {
                        navigate("/geschenken");
                    }}
                />
            </div>
        }
      >
        <StatsCardTop20 />
      </Card>

      <Card
        className='hover:shadow-3 transition-duration-200 '
        id='stats-card-orders'
        header={statsCardOrdersHeader(
          "Recente bestellingen",
          ordersDropdownSelected,
          dropdownOptions,
          onOrdersDropdownChange
        )}>
        <StatsCardOrders
          timespan={ordersDropdownSelected}
          limit={10}
          fullWidth={false}
        />
        <Button
          className='p-button-text p-button-success m-auto w-full'
          label='Meer orders'
          onClick={() => {
            navigate("/orders");
          }}
        />
      </Card>
    </div>
  );
}
